import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import useMunicipality from '../hooks/useMunicipality';
import AppDownloadSection from './AppDownloadSection';

function Footer() {
    const { municipality } = useMunicipality();
    return (
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{  opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
            
            delay: 1
        }}
            className="bottom-0 z-10 flex-col justify-center w-full rounded-t-[96px]  bg-gradient-to-t from-[#007975] to-[#A8E7AA]">
            {/* <img src={footer_bg} alt="" className='relative' /> */}
            
            <footer className=" container relative  my-auto justify-between mx-auto text-center lg:text-left bg-gradient-to-t from-[#007975] to-[#A8E7AA] text-white rounded-t-[96px]">
            
                <div className="px-10 py-10 text-center md:text-left bg-footerimg bg-contain bg-bottom">
                
                    <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-2 space-x-5 ">
                        
                        <div className="grid-flow-col-dense">
                            <h6 className="font-semibold mb-4 md:justify-start">
                                Ποιοί Είμαστε
                            </h6>
                            <p>
                                Το σύνθημα: followgreen – follow your city! (TM). Ένα γραφείο επιστημόνων με διεθνή εμπειρία έφτιαξε την Smart City πλατφόρμα ενώνοντας τον Δήμο με Πολίτες, Σχολεία, Κοινωνικές Δομές & Τοπικές Επιχείρησεις για την ευαισθητοποίηση & επιβράβευση της προστασίας περιβαλλοντος. Όλοι μαζί: Πολίτες, εκπαιδευτικοί, γονείς, μαθητές, ιδιοκτήτες καταστημάτων & Δήμος αναλαμβάνουν τώρα περιβαλλοντική δράση!
                            </p>
                            
                        </div>
                        {/* <div className="">
                            <h6 className="font-semibold mb-4 flex justify-center md:justify-start">
                                Πλοήγηση
                            </h6>
                            <p className="mb-4">
                                <a href="#!" className="">Αρχική</a>
                            </p>
                            <p className="mb-4">
                                <a href="#!" className="">Κέρδισε</a>
                            </p>
                            <p className="mb-4">
                                <a href="#!" className="">Εξαργύρωσε</a>
                            </p>
                            <p>
                                <a href="#!" className="">Ανακύκλωσε</a>
                            </p>
                            <p>
                                <a href="#!" className="">Σχολεία</a>
                            </p>
                        </div> */}
                        <div className="flex flex-col">
                            <p className="font-semibold mb-4 flex justify-center md:justify-start">
                                Χρήσιμα
                            </p>
                            <p className="mb-4">
                                <Link to={municipality.LocalityEn + '/home/how'} className="">Πώς λειτουργεί</Link>
                            </p>
                            <p className="mb-4">
                                <Link to={municipality.LocalityEn + '/home/localbusinesses'} className="">Τοπικές Επιχειρήσεις</Link>
                            </p>
                            <p className="mb-4">
                                <Link to={municipality.LocalityEn + '/home/terms'} className="">Όροι Χρήσης</Link>
                            </p>
                            <p className="mb-4">
                                <Link to={municipality.LocalityEn + '/home/policy'} className="">Πολιτική Απορρήτου</Link>
                            </p>
                            <p className="mb-4">
                                <Link to={municipality.LocalityEn + '/home/contact'} className="">Επικοινωνία</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <span className='flex justify-center !text-center py-2'>© Copyright 2025 by Followgreen | All rights reserved - Trademark Ν241251/2017</span>
            </footer>


        </motion.div>
    );
}

export default Footer;
