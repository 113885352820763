import {
    Link,
    useLocation
} from "react-router-dom";

import earnImg19 from '../assets/earn/19.jpg';
import earnImg20 from '../assets/earn/20.jpg';
import earnImg3 from '../assets/earn/3.jpg';


import { motion } from "framer-motion";
import { BsRecordCircleFill } from "react-icons/bs";
import { useQuery } from "react-query";
import useMunicipality from "../hooks/useMunicipality";
import { useState } from "react";
import { getAllContestSchools, getAllLiveVotes, getAllSchoolContests, getAllSchoolProjects, getAllSchools, getLastSchoolContest, getLiveVote } from "../api";
import { CircularProgress } from "@mui/material";
import { getImage } from "../utils/utils";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import SportClubs from "./SportCllubs";
import useAuth from "../hooks/useAuth";
const schhools = [
    {
        id: 1,
        name: '6ο Νηπιαγωγείο Αμπελοκήπων',
        href: '#',
        imageSrc: earnImg3,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: 'Πόντοι ανά μαθητή',
        points: 4362.87,
    },
    {
        id: 2,
        name: '6ο Νηπιαγωγείο Αμπελοκήπων',
        href: '#',
        imageSrc: earnImg19,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: 'Πόντοι ανά μαθητή',
        points: 4362.87,
    },
    {
        id: 3,
        name: '6ο Νηπιαγωγείο Αμπελοκήπων',
        href: '#',
        imageSrc: earnImg20,
        imageAlt: "Front of men's Basic Tee in black.",
        tag: 'Πόντοι ανά μαθητή',
        points: 4362.87,
    },




    // More products...
]



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



export default function Schools({ isHomePage = false }) {
    const { pathname } = useLocation();

    const { municipality } = useMunicipality();

    const [maxSchools, setMaxSchools] = useState(0);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);

    const { logout, user, isAuthenticated, updateUserValues } = useAuth();

    const { isLoading, isError, error, data, isFetching, } =
        useQuery(['schools', municipality],
            () =>
                getAllContestSchools({ municipalityId: municipality.MunicipalityId }),
            {
                refetchOnWindowFocus: false,
                refetchIntervalInBackground: false,
                keepPreviousData: true
            })



    const { isLoading: isLiveVotenLoading, isError: isLiveVoteError, data: liveVotes, } =
        useQuery('all_live_votes', () => getAllLiveVotes({ municipalityId: municipality.MunicipalityId }), {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        })





    const getProjectPointsBySchool = (schoollId) => {
        let points = 0
        for (let index = 0; index < data.projects.length; index++) {
            const element = data.projects[index];
            if (element.SchoolId === schoollId)
                points = element.BalancePoints;

        }

        return points;
    }

    const cardVariants = {
        initial: {
            y: 300,
        },
        exit: {
            y: 0
        },
        animate: {
            y: 0,
            rotate: 0,
            transition: {
                type: "spring",
                bounce: 0.6,
                duration: 1
            }
        }
    };


    const canShowVote = () => {
        return true;
        if (liveVotes.vote) {
            if (municipality.LocalityEn === 'vvv') return true;
            let expireTime = new Date(liveVotes.vote.EndDate).getTime();
            let nowtime = new Date().getTime();
            if (expireTime > nowtime)
                return true;
            else
                return false;
        }

        return false;
    }



    //error handlers
    if (isLoading || isLiveVotenLoading)
        return <div className='flex justify-center max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8'><CircularProgress></CircularProgress></div>

    if (isError || isLiveVoteError) {

        return <div className='flex justify-center max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>ο δήμος δέν εχει  σχολεία </p>
            </div>
        </div>
    }

    if (data.length < 1)
        return <div className='flex p-8 justify-center h-full items-center'>
            <div className=' text-center'>

                <p className='texgt-md font-medium'>No Data To Load</p>
            </div>
        </div>








    return (
        <div className="bg-white">
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                {!isHomePage ?

                    <motion.div
                        className="card-container"
                    // variants={cardVariants}
                    // initial="initial"
                    // animate="animate"
                    // exit="exit"
                    // viewport={{ once: true, amount: 0.8 }}
                    >

                        <h2 className="pb-8 text-3xl font-bold text-gray-700">Ανακύκλωση στα σχολεία</h2>
                        <h2 className="pb-3 text-3xl font-semibold text-gray-700">{data.contest.TitleEl}</h2>
                        <h2 className="text-lg font-semibold text-gray-700">Ανακάλυψε τα σχολεία που συμμετέχουν στη δράση, δώρισε πόντους και ενίσχυσε την προσπάθεια των μαθητών.</h2>


                    </motion.div>

                    : <></>}
                {/* <div className="grid grid-cols-1 mt-6 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8"> */}
                <div className="">
                    <Carousel
                        className="pb-0 pt-5"
                        swipeable={true}
                        draggable={true}
                        showDots={isHomePage ? false : true}
                        responsive={responsive}

                        // renderButtonGroupOutside={true}
                        // arrows={false}
                        // centerMode={true}
                        // ssr={false} // means to render carousel on server-side.
                        infinite={true}
                        // focusOnSelect={false}
                        autoPlay={true}
                        // autoPlaySpeed={1000}
                        // keyBoardControl={true}
                        // customTransition="all .5"
                        // transitionDuration={500}

                        // containerClass="carousel-container"
                        // removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style pt-10 !mt-10"
                    // itemClass="carousel-item-padding-40-px"
                    >
                        {data.schools.map((school, index) => {

                            // if (index > 2)
                            //     return null;
                            // else {

                            return <motion.div
                                variants={cardVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                viewport={{ once: true, amount: 0.8 }} key={index} className="relative overflow-hidden duration-200 m-5 ease-out shadow-lg cursor-pointer rounded-2xl group hover:-translate-y-2">
                                <div className="relative w-full rounded-md  max-h-80 ">
                                    <div >

                                        <LazyLoadImage
                                            src={(school.Image === null || school.Image.includes('ΦΩΤΟΓΡΑΦΙΑ ΣΧΟΛΕΙΟΥ') || (school.Image.includes('ΦΩΤΟ.png'))) ? getImage('/images/school/default.jpg') : getImage(school.Image)}
                                            alt=''
                                            // loading="lazy"
                                            // onDrag={(e)=>{e.preventDefault()}}
                                            draggable={false}
                                            className="flex object-cover h-56 w-full   rounded-t-2xl"
                                        />


                                        <div draggable={false} className=" flex absolute bottom-0 w-full min-h-14 content-between justify-between pl-3 pb-2 opacity-90 bg-app_green ">
                                            <div className="w-44 leading-[17px] text-base text-left flex justify-center items-center font-semibold text-white">
                                                {school.Name}
                                            </div>
                                            <div className="w-1/2 flex-col text-md text-center flex justify-center items-center font-semibold text-white">
                                                <div className="text-sm text-right">Πόντοι/συμμετοχή</div>
                                                {/* <div>{lastContests.ProjectPoints / school.StudentCount}</div> */}
                                                <div onClick={() => {

                                                }}>{((getProjectPointsBySchool(school.SchoolId)) / school.StudentCount).toFixed(2)}</div>

                                            </div>


                                        </div>


                                    </div>



                                </div>

                            </motion.div>
                        }
                            // }


                        )}
                    </Carousel>

                </div>

                {!isHomePage ?

                    <div className="flex-1 pt-12 ">

                        <Link
                            to={'index/' + data.contest.SchoolContestId.toString()}
                            // state={{ offer: offer, citizenFavorites: citizenFavorites, merchant: getMerchant(offer.MerchantId) }}
                            className="flex w-44 mb-6 shadow-lg shadow-gray-300 bg-white hover:bg-gradient-to-br drop-shadow-md hover:shadow-md text-app_brown font-semibold rounded-full text-md px-5 text-center h-11 ">
                            <div className='flex items-center'><BsRecordCircleFill></BsRecordCircleFill>
                                <p className='pl-1.5 pb-0.5'>{'περισσοτερα'.toUpperCase()}</p></div>
                        </Link>

                        {/* <h2 className="pt-16 pb-16 text-2xl font-semibold text-gray-700">Αυτή την στιγμή δεν υπάρχει ενεργός διαγωνισμός για τους αθλητικούς συλλόγους του Δήμου σου. </h2> */}


                    </div>





                    : <></>}


            </div>


            <div className="grid container flex-col-reverse grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-1 max-w-2xl px-4 py-8 mx-auto justify-content-center sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8 ">

                {liveVotes.map((vote, index) => {

                    return <div className={`${isHomePage ? "lg:mb-3 mb-2" : "mb-0"} !max-w-xl flex justify-center px-4 mt-0 lg:-mt-14 pb-8 mx-auto sm:py-10 sm:px-6 lg:max-w-7xl lg:px-4` }>

                        {(canShowVote()) ?
                       
                                <div className='flex items-stretch justify-center h-full  relative p-1 bg-gradient-to-l from-green-400 via-blue-500 to-green-500  overflow-hidden duration-200 ease-out shadow-2xl cursor-pointer rounded-3xl group  hover:-translate-y-2'>
                                    <Link to={isHomePage ? `./vote/single/${vote.VoteId}` : `./../vote/single/${vote.VoteId}`}>
                                        <div className='bg-white w-full h-full rounded-3xl relative'>
                                            <img
                                                loading="lazy"
                                                src={liveVotes[index].VoteImage ? ((liveVotes[index].VoteImage.includes('digitalocean') ? "" : "https://followgreen.fra1.digitaloceanspaces.com") + liveVotes[index].VoteImage) : ''}
                                                alt=''
                                                className="object-cover object-center w-full p-0 lg:w-full rounded-3xl"
                                            />

                                            <div className="p-3.5  flex flex-col _justify-center items-center justify-between">
                                                <div className="flex   justify-center text-[17px] font-semibold text-center text-black">

                                                    {liveVotes[index].TitleEl}
                                                </div>
                                                <div className="flex justify-center text-ellipsis overflow-hidden">
                                                {liveVotes[index].SubtitleEl}
                                                </div>
                                            </div>

                                        </div>
                                    </Link>

                                </div>
                           

                            : null}


                    </div>

                })}
            </div>





            {!isHomePage ? <SportClubs isHomePage={false}></SportClubs> : null}


        </div>
    )
}