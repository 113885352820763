import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import HomeMunicipality from "./pages/HomeMunicipality";

// import Helmet from "react-helmet";
import { useEffect, useState, lazy, Suspense } from "react";
import Dashboard from "./admin/Dashboard";
import useAuth from "./hooks/useAuth";

import useMunicipality from "./hooks/useMunicipality";
import { Article } from "@mui/icons-material";
import SingleArticle from "./pages/SingleArticle";
import SingleBlogPost from "./pages/SingleBlogPost";
import SingleOffer from "./pages/SingleOffer";
import { AuthProvider } from "./contexts/JWTAuthContext";
import SingleVideo from "./pages/SingleVideo";
import SingleQuiz from "./pages/SingleQuiz";
import Landing from "./pages/Landing";
import UserPost from "./pages/UserPost";
import AccountSettings from "./pages/AccountSettings";
import PrintCoupon from "./components/PrintCoupon";
import AccountDetailsEdit from "./components/AccountDetailEdit";
import CouponToPrint from "./pages/CouponToPrint";
import AccountChangePassword from "./components/AccountChangePassword";
import SchoolsContest from "./pages/SchoolsContest";
import AppDownloadSection from "./components/AppDownloadSection";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import LocalBussinesses from "./pages/LocalBussinesses";
import SingleMission from "./pages/SingleMission.jsx";
import SingleMerchant from "./pages/SingleMerchant";
import ConfirmEmail from "./pages/ConfirmEmail";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import Contact from "./pages/Contact";
import Coupon from "./pages/Coupon";
import SportClubs from "./pages/SportCllubs";
import SchoolsContestDesctiption from "./pages/SchoolsContestDesctiption";
import SchoolUserView from "./pages/SchoolUserView";
import PublicOrganizationView from "./pages/PublicOrganizationView";
import SingleVote from "./pages/SingleVote";
import SingleMissionNext from "./pages/SingleMissionNext";
import ReactGA from "react-ga";
import QrScan from "./pages/QrScan";
import QrScanNotFind from "./pages/QrScanNotFind";
import SingleVoteWinners from "./pages/SingleVoteWinners";
import { getMunicipalityByLocality } from "./api";

//Lazy loading of routes
const Action = lazy(() => import("./pages/Action"));
const How = lazy(() => import("./pages/How"));
const Login = lazy(() => import("./pages/Login"));
const MerchantsMap = lazy(() => import("./pages/MerchantsMap"));
const News = lazy(() => import("./pages/News"));
const NotFind = lazy(() => import("./pages/NotFind"));
const Recyclebucket = lazy(() => import("./pages/Recyclebucket"));
const Register = lazy(() => import("./pages/Register"));
const Ofers = lazy(() => import("./pages/Ofers"));
const Schools = lazy(() => import("./pages/Schools"));
const Earn = lazy(() => import("./pages/Earn"));

const TRACKING_ID = "UA-87906552-1"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

function App() {
  const { municipality,init } = useMunicipality();
  const { user, isAuthenticated, updateUserValues, logout } = useAuth();
  

  const navigate = useNavigate();

  let [currentMunicipality, setCurrentMunicipality] = useState(
    municipality.LocalityEn
  );

  let [isLive, setIsLive] = useState(
    true
  );

  const location = useLocation();
  useEffect(() => {
    
    if(location.pathname.split("/")[1] == 'new_comming_soon'){
      navigate("./" + 'new_comming_soon');
      return;
    }


    //added for disabeling the municipality
    getMunicipalityByLocality(location.pathname.split("/")[1]).then((m) => {    
      if(m.IsLive === 0 && location.pathname.split("/")[1]){
        setIsLive(false);        
      }
    });

    if (isAuthenticated) {
      // console.log("UPDATING USER VALUES");
      console.log("USER", user.localityEn);
      console.log("LINK", location.pathname.split("/")[1]);
      console.log("NOW", municipality.LocalityEn);

      if(municipality.LocalityEn == 'new_comming_soon' && location.pathname.split("/")[1] != 'new_comming_soon')
      {
        getMunicipalityByLocality(user.localityEn).then((m) => {
          const newMunicipality = {
            NameEl: m.NameEl,
            MunicipalityId: m.MunicipalityId,
            CenterLatitude: m.CenterLatitude,
            CenterLongitude: m.CenterLongitude,
            LocalityEn: m.LocalityEn,
            RecycleBucketText: m.RecycleBucketText,
            IsGreenSchool: m.IsGreenSchool,
            MobileEnabled: m.MobileEnabled,
          };
          init(newMunicipality);
        });
        setCurrentMunicipality(user.localityEn);
        navigate("./" + user.localityEn);
      }
      
      if (user.localityEn !== location.pathname.split("/")[1]) {
        setCurrentMunicipality(user.localityEn);
        navigate("./" + user.localityEn);
      }

      updateUserValues();
    }
    else{
      if(municipality.LocalityEn == 'new_comming_soon' && location.pathname.split("/")[1] != 'new_comming_soon'){
        getMunicipalityByLocality(location.pathname.split("/")[1]).then((m) => {
          const newMunicipality = {
            NameEl: m.NameEl,
            MunicipalityId: m.MunicipalityId,
            CenterLatitude: m.CenterLatitude,
            CenterLongitude: m.CenterLongitude,
            LocalityEn: m.LocalityEn,
            RecycleBucketText: m.RecycleBucketText,
            IsGreenSchool: m.IsGreenSchool,
            MobileEnabled: m.MobileEnabled,
          };
          init(newMunicipality);
        });
      }
      setCurrentMunicipality(municipality.LocalityEn);
      
    }

    // ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log("LOCATION", location.pathname.split("/")[1]);
    //console.log("MUNICIPALITY CHANGED TO: " + currentMunicipality.TitleEl);
  }, [municipality]);

  useEffect(() => {
    // console.log("CHECKING PAGE");

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

   //added for disabeling the municipality
  if(!isLive){
    // localStorage.removeItem("app_selected_municipality")
    if (isAuthenticated){
      //logout
      logout();
      //redirect to landing
      localStorage.removeItem("app_selected_municipality")
      
    }    
    return <NotFind isLive={false}></NotFind>
  }

  return (
    <div>
      {/* <Helmet>
        <title>Followgreen App</title>
        <meta name="description" content="Description of Followgreen App" />
        <meta name="theme-color" content="#008f68" />
        <body class="dark" />
      </Helmet> */}

      {<Navbar></Navbar>}
      {/* <Link to={"schools"}>Click</Link> */}
      {/* <AnimatePresence> */}
      <Suspense>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            replace
            element={(() => {
              //console.log("IsAuth:", isAuthenticated);

              console.log("ISLIVE", isLive);

              

              if (isAuthenticated) {
                if (municipality.LocalityEn === "new_comming_soon") {
                  return <Landing></Landing>;
                } else return <Navigate to={currentMunicipality} />;
              } else {
                return <Landing></Landing>;
              }
            })()}
          ></Route>
          <Route
            path="offer/coupon/:couponcode"
            element={<Coupon></Coupon>}
          ></Route>
          <Route path="*" element={<NotFind></NotFind>}></Route>

          {/* Qr scann Routes */}
          <Route
            path="arta/scanqr/22cb41df-57a5-4dbe-9d09-a5beab17661b"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="moschato-tavros/scanqr/2c6d21ca-39c3-44a4-af1e-4362e9e80c0a"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="peristeri/scanqr/e0544ebf-6e9e-45f4-8e0a-e36641b2cc65"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="komotini/scanqr/ad80e951-d096-4e9d-b521-f15c933bb33a"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="orestiada/scanqr/f14b71a5-e288-4621-b184-0ce15464520e"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="pallini/scanqr/b83f1a0f-a5cb-4eda-bf06-77a15cb0d82f"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="koropi/scanqr/cc644423-220c-466a-9829-f136baf0f323"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="vrilissia/scanqr/16f3eef2-4b54-4a71-8ec2-009f3c6928e5"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="pilea-hortiatis/scanqr/6d5e6cfd-de98-40d5-9612-1a1d7be849a2"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="ampelokipi-menemeni/scanqr/4b1cd5cd-4cda-4a93-a26e-fdb09f45312b"
            element={<QrScan></QrScan>}
          ></Route>
          <Route
            path="drama/scanqr/3b1cd5cv-6ttv-4a93-a21e-fdb8cf45434a"
            element={<QrScan></QrScan>}
          ></Route>

          <Route
            path=":municipalityId/scanqr/:qr"
            element={<QrScanNotFind></QrScanNotFind>}
          ></Route>
          {/* <Route path="/admin" element={<Dashboard></Dashboard>}></Route> */}

          <Route path=":municipalityId">
            <Route index element={<HomeMunicipality></HomeMunicipality>} />

            <Route path="earn" element={<Earn></Earn>}></Route>

            <Route path="blog">
              {/* <Route index element={<NotFind></NotFind>} /> */}
              <Route path="single">
                <Route index element={<NotFind></NotFind>} />
                <Route
                  path=":blogId"
                  element={<SingleBlogPost></SingleBlogPost>}
                ></Route>
              </Route>
            </Route>

            <Route path="video">
              <Route index element={<NotFind></NotFind>} />
              <Route path="single">
                <Route index element={<NotFind></NotFind>} />
                <Route
                  path=":videoId"
                  element={<SingleVideo></SingleVideo>}
                ></Route>
              </Route>
            </Route>

            <Route path="quiz">
              <Route index element={<NotFind></NotFind>} />
              <Route path="single">
                <Route index element={<NotFind></NotFind>} />
                <Route
                  path=":quizId"
                  element={<SingleQuiz></SingleQuiz>}
                ></Route>
              </Route>
            </Route>

            <Route path="offer">
              <Route index element={<Ofers></Ofers>} />
              <Route
                path="coupon/:couponcode"
                element={<Coupon></Coupon>}
              ></Route>
              <Route
                path="merchantmap"
                element={<MerchantsMap></MerchantsMap>}
              ></Route>
              <Route
                path="merchant/:merchantUrl"
                element={<SingleMerchant></SingleMerchant>}
              ></Route>
              <Route path="detail">
                <Route index element={<NotFind></NotFind>} />
                <Route
                  path=":offerId"
                  element={<SingleOffer></SingleOffer>}
                ></Route>
              </Route>
            </Route>

            <Route path="action" element={<Action></Action>}></Route>
            <Route path="sportclub" element={<SportClubs></SportClubs>}></Route>
            <Route path="contest">
              <Route index element={<Schools></Schools>} />
              <Route
                path="index/:contestId/description"
                element={
                  <SchoolsContestDesctiption></SchoolsContestDesctiption>
                }
              ></Route>
              <Route
                path="index/:contestId"
                element={<SchoolsContest></SchoolsContest>}
              ></Route>
            </Route>

            <Route path="news">
              <Route index element={<News></News>} />
              <Route path="single">
                <Route index element={<NotFind></NotFind>} />
                <Route
                  path=":articleId"
                  element={<SingleArticle></SingleArticle>}
                ></Route>
              </Route>
            </Route>
            <Route
              path="recyclebucket"
              element={<Recyclebucket></Recyclebucket>}
            ></Route>

            <Route path="userpost" element={<UserPost></UserPost>}></Route>

            <Route path="member">
              <Route index element={<AccountSettings></AccountSettings>} />
              <Route
                path="changedetails"
                element={<AccountDetailsEdit></AccountDetailsEdit>}
              ></Route>
              <Route
                path="changepassword"
                element={<AccountChangePassword></AccountChangePassword>}
              ></Route>
            </Route>
            <Route
              path="member/printcoupon/:id"
              element={<PrintCoupon></PrintCoupon>}
            ></Route>

            <Route path="account">
              {/* <Route index element={<Ofers></Ofers>} /> */}
              <Route path="login" element={<Login></Login>}></Route>
              <Route
                path="schooluserview"
                element={<SchoolUserView></SchoolUserView>}
              ></Route>
              <Route
                path="publicorganizationview"
                element={<PublicOrganizationView></PublicOrganizationView>}
              ></Route>
              <Route
                path="ConfirmEmail"
                element={<ConfirmEmail></ConfirmEmail>}
              ></Route>
              <Route
                path="ResetPassword"
                element={<ResetPasswordConfirm></ResetPasswordConfirm>}
              ></Route>
              <Route path="register" element={<Register></Register>}></Route>
            </Route>
            <Route path="home">
              {/* <Route index element={<Ofers></Ofers>} /> */}
              <Route path="how" element={<How></How>}></Route>
              <Route path="terms" element={<Terms></Terms>}></Route>
              <Route path="contact" element={<Contact></Contact>}></Route>
              <Route path="policy" element={<Policy></Policy>}></Route>
              <Route
                path="localbusinesses"
                element={<LocalBussinesses></LocalBussinesses>}
              ></Route>
            </Route>

            <Route path="mission">
              <Route index element={<NotFind></NotFind>} />
              <Route path="single">
                <Route index element={<NotFind></NotFind>} />
                <Route
                  path="currnet"
                  element={<SingleMission></SingleMission>}
                ></Route>
                <Route
                  path="currnet2"
                  element={<SingleMissionNext></SingleMissionNext>}
                ></Route>
              </Route>
            </Route>

            <Route path="vote">
              <Route index element={<NotFind></NotFind>} />
              <Route path="single">
                <Route index element={<NotFind></NotFind>} />
                <Route
                  path=":voteId"
                  element={<SingleVote></SingleVote>}
                ></Route>

                <Route
                  path=":voteId/winners"
                  element={<SingleVoteWinners></SingleVoteWinners>}
                ></Route>
              </Route>
            </Route>
          </Route>
        </Routes>
        <AppDownloadSection></AppDownloadSection>
        <Footer></Footer>
      </Suspense>

      {/* </AnimatePresence> */}
    </div>
  );
}

export default App;
