// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const SchoolPieChart = ({ data /* see data tab */ }) => (
    <div className='p-0 flex w-full h-full'>
        <ResponsivePie

            data={data}
            margin={{ top: 25, right: 80, bottom: 25, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            arcLinkLabelsDiagonalLength={8}
            activeInnerRadiusOffset={10}
            activeOuterRadiusOffset={8}

            arcLinkLabelsTextOffset={3}
            // colors={{ scheme: null, }}
            // color={{ scheme: 'color', }}
            // borderColor={{ theme: 'background' }}
            // arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
            arcLinkLabelsThickness={2}
            // arcLinkLabelsThickness={5}
            // arcLinkLabel={}
            // arcLabel={'s'}
            // arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={true}
            // arcLabelsSkipAngle={7}
            // arcLinkLabelsSkipAngle={7}
            // arcLabelsTextColor={{
            //     from: 'color',
            //     modifiers: [
            //         [
            //             'darker',
            //             1
            //         ]
            //     ]
            // }}
            defs={[
                {
                    id: 'Χαρτί',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#faf047' },
                        { offset: 100, color: '#faf047' },
                    ],

                },
                {
                    id: 'Πλαστικό',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#ff8717ff' },
                        { offset: 100, color: '#ff8717ff' },
                    ],

                },
                {
                    id: 'Μπαταρίες',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#9e9e9eff' },
                        { offset: 100, color: '#9e9e9eff' },
                    ],

                },
                {
                    id: 'Αλουμίνιο',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#f53913ff' },
                        { offset: 100, color: '#f53913ff' },
                    ],

                },
                {
                    id: 'AHHE',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#dbdbdbff' },
                        { offset: 100, color: '#dbdbdbff' },
                    ],

                },
                {
                    id: 'Τηγανέλαιο',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#5D800B' },
                        { offset: 100, color: '#5D800B' },
                    ],

                },
                {
                    id: 'Τηγανέλαιο',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#5D800B' },
                        { offset: 100, color: '#5D800B' },
                    ],

                },
                {
                    id: 'Organic',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#B26F31' },
                        { offset: 100, color: '#B26F31' },
                    ],

                },
                {
                    id: 'Ρούχα',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#fc8bd2ff' },
                        { offset: 100, color: '#fc8bd2ff' },
                    ],

                },
                {
                    id: 'Καπάκια',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#ff6505ff' },
                        { offset: 100, color: '#ff6505ff' },
                    ],

                },
                {
                    id: 'Δωρεά',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#a9e20cff' },
                        { offset: 100, color: '#a9e20cff' },
                    ],

                },
                {
                    id: 'Ανακυκλώσιμα υλικά',
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: '#1783FFFF' },
                        { offset: 100, color: '#1783FFFF' },
                    ],

                },

            ]}
            fill={[
                {
                    match: {
                        id: 'Χαρτί'
                    },
                    id: 'Χαρτί'
                },
                {
                    match: {
                        id: 'Πλαστικό'
                    },
                    id: 'Πλαστικό'
                },
                {
                    match: {
                        id: 'Μπαταρίες'
                    },
                    id: 'Μπαταρίες'
                },
                {
                    match: {
                        id: 'Αλουμίνιο'
                    },
                    id: 'Αλουμίνιο'
                },
                {
                    match: {
                        id: 'AHHE'
                    },
                    id: 'AHHE'
                },
                {
                    match: {
                        id: 'Τηγανέλαιο'
                    },
                    id: 'Τηγανέλαιο'
                },
                {
                    match: {
                        id: 'Organic'
                    },
                    id: 'Organic'
                },
                {
                    match: {
                        id: 'Ρούχα'
                    },
                    id: 'Ρούχα'
                },
                {
                    match: {
                        id: 'Καπάκια'
                    },
                    id: 'Καπάκια'
                },
                {
                    match: {
                        id: 'Δωρεά'
                    },
                    id: 'Δωρεά'
                },
                {
                    match: {
                        id: 'Ανακυκλώσιμα υλικά'
                    },
                    id: 'Ανακυκλώσιμα υλικά'
                },
                

            ]}
        // motionConfig="default"

        // legends={[]}
        />
    </div>
)

export default SchoolPieChart;